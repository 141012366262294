// src/routes/WorkspaceRoutes.js
import React, { useState, useEffect, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useWorkspace } from "./context/WorkspaceContext";
import { isMobile as checkIsMobile, handleResize, debounce } from './utils/utils';

// Import LoadingIndicator and navigation components
import { LoadingIndicator } from "./components/Tools/LoadingIndicator";
import VerticalNavbar from "./components/Navigation/VerticalNavbar/VerticalNavbar";
import HorizontalMobileNavbar from "./components/Navigation/HorizontalMobileNavbar/HorizontalMobileNavbar";
import TopVerticalBar from "./components/Navigation/TopVerticalBar/TopVerticalBar";

// Lazy load pages
const Dashboard = React.lazy(() => import("./pages/Protected/Dashboard/Dashboard"));
const Upload = React.lazy(() => import("./pages/Protected/Content/Upload/Upload"));
const Schedule = React.lazy(() => import("./pages/Protected/Schedule/Schedule"));
const Templates = React.lazy(() => import("./pages/Protected/Tools/Templates/Templates"));
const History = React.lazy(() => import("./pages/Protected/History/History"));
const Socials = React.lazy(() => import("./pages/Protected/Socials/Socials"));
const Settings = React.lazy(() => import("./pages/Protected/Settings/Settings"));
const Support = React.lazy(() => import("./pages/Protected/Support/Support"));
const Library = React.lazy(() => import("./pages/Protected/Library/Library"));
const InstagramGrid = React.lazy(() => import("./pages/Protected/Tools/InstagramGrid/InstagramGrid"));
const Tools = React.lazy(() => import("./pages/Protected/Tools/Tools"));
const EarningsCalculator = React.lazy(() => import("./pages/Public/Tools/EarningsCalculator"));
const LinkInBio = React.lazy(() => import("./pages/Protected/LinkInBio/LinkInBio"));
const LinkInBioPublic = React.lazy(() => import("./pages/Public/LinkInBioPublic"));

// Lazy load callback routes
const Instagram = React.lazy(() => import("./pages/Protected/Callbacks/Instagram"));
const Google = React.lazy(() => import("./pages/Protected/Callbacks/Google"));
const Tiktok = React.lazy(() => import("./pages/Protected/Callbacks/Tiktok"));
const Facebook = React.lazy(() => import("./pages/Protected/Callbacks/Facebook"));
const X = React.lazy(() => import("./pages/Protected/Callbacks/X"));
const Threads = React.lazy(() => import("./pages/Protected/Callbacks/Threads"));

// Special pages
const NoWorkspace = React.lazy(() => import("./pages/Protected/Settings/components/NoWorkspace/NoWorkspace"));

// Fallback component for lazy loading
const PageLoader = () => <div className="flex justify-center items-center min-h-[calc(100vh-100px)]">
    <LoadingIndicator showLogo={true} />
</div>;

function WorkspaceRoutes() {
    const [isMobile, setIsMobile] = useState(checkIsMobile());

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    const {
        loading,
        workspaces,
    } = useWorkspace();

    if (loading) {
        return <LoadingIndicator showLogo={true} className="h-screen w-screen" />;
    }

    // Render navbars based on userLoggedIn and isMobile
    return (
        <div className={`flex ${isMobile && 'flex-col'} min-h-screen w-full`}>
            {!isMobile && <VerticalNavbar />}

            <div className="flex flex-col flex-1">
                <TopVerticalBar />

                {/* Existing routing logic */}
                {workspaces.length === 0 ? (
                    <Suspense fallback={<PageLoader />}>
                        <Routes>
                            <Route path="/dashboard/no-workspace" element={<NoWorkspace />} />
                            <Route path="*" element={<Navigate to="/dashboard/no-workspace" />} />
                        </Routes>
                    </Suspense>
                ) : (
                    <Suspense fallback={<PageLoader />}>
                        <Routes>
                            <Route path="/" element={<Navigate to="/dashboard" />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/dashboard/upload" element={<Upload />} />
                            <Route path="/dashboard/library" element={<Library />} />
                            <Route path="/dashboard/schedule" element={<Schedule />} />
                            <Route path="/dashboard/posts" element={<History />} />
                            <Route path="/dashboard/socials" element={<Socials />} />

                            {/* Settings and nested routes */}
                            <Route path="/dashboard/settings/*" element={<Settings />} />

                            <Route path="/dashboard/support" element={<Support />} />

                            {/* TOOLS */}
                            <Route path="/dashboard/tools" element={<Tools />} />
                            <Route path="/dashboard/tools/templates" element={<Templates />} />
                            <Route path="/dashboard/tools/instagram-grid" element={<InstagramGrid />} />
                            <Route path="/dashboard/tools/earnings-calculator" element={<EarningsCalculator />} />
                            <Route path="/dashboard/tools/link-in-bio" element={<LinkInBio />} />
                            <Route path="/linkinbio/:customUrl" element={<LinkInBioPublic />} />
                            {/* Callbacks */}
                            <Route path="/callback/instagram" element={<Instagram />} />
                            <Route path="/callback/google" element={<Google />} />
                            <Route path="/callback/tiktok" element={<Tiktok />} />
                            <Route path="/callback/facebook" element={<Facebook />} />
                            <Route path="/callback/x" element={<X />} />
                            <Route path="/callback/threads" element={<Threads />} />

                            {/* Anything else not matched => go to dashboard */}
                            <Route path="*" element={<Navigate to="/dashboard" />} />
                        </Routes>
                    </Suspense>
                )}
            </div>

            {isMobile && <HorizontalMobileNavbar />}
        </div>
    );
}

export default WorkspaceRoutes;
