import React from "react";

// Define your button styles
const buttonStyles = {
    base: "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-1 focus:ring-gray-300 disabled:pointer-events-none disabled:opacity-50",
    variants: {
        default: "bg-blue-500 !text-white hover:bg-blue-600",
        destructive: "bg-red-500 text-white hover:bg-red-600",
        outline: "border border-gray-300 bg-transparent text-gray-500 hover:bg-gray-200",
        secondary: "bg-gray-500 text-white hover:bg-gray-600",
        ghost: "bg-transparent text-gray-700 hover:bg-gray-100",
        link: "text-blue-500 underline hover:text-blue-600",
        primary: "bg-indigo-600 !text-white hover:bg-indigo-500",
    },
    sizes: {
        default: "h-9 px-4 py-2",
        sm: "h-8 px-3 text-xs",
        lg: "h-12 px-6",
        icon: "h-9 w-9",
        xl: "h-12 px-10 text-lg",
    },
};

// Button component
const Button = React.forwardRef(
    ({ className = "", variant = "default", size = "default", style = {}, ...props }, ref) => {
        const variantClass = buttonStyles.variants[variant] || buttonStyles.variants.default;
        const sizeClass = buttonStyles.sizes[size] || buttonStyles.sizes.default;

        return (
            <button
                className={`${buttonStyles.base} ${variantClass} ${sizeClass} ${className}`}
                style={style}
                ref={ref}
                {...props}
            />
        );
    }
);

Button.displayName = "Button";

export { Button };
