// App.js
import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WorkspaceProvider } from './context/WorkspaceContext';
import './firebase';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import axios from "axios";
import { LoadingIndicator } from "./components/Tools/LoadingIndicator";
import WorkspaceRoutes from "./WorkspaceRoutes";

// Lazy load pages
const LoginPage = lazy(() => import("./pages/Auth/Login"));
const SignupPage = lazy(() => import("./pages/Auth/Signup"));
const LinkInBioPublic = lazy(() => import("./pages/Public/LinkInBioPublic"));

// Define React Router future flags
const routerFutureConfig = {
  v7_startTransition: true,
  v7_relativeSplatPath: true
};

// Fallback component for lazy loading
const PageLoader = () => <div className="flex justify-center items-center min-h-screen">
  <LoadingIndicator showLogo={true} />
</div>;



const App = () => {
  const [theme] = useLocalStorage("theme", 'light');
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        setUserLoggedIn(false);
        setIsLoading(false);
      } else {
        try {
          // Force a token refresh if needed - the 'true' parameter forces refresh
          const token = await firebaseUser.getIdToken(true);
          console.log('token refreshed', token);
          // Check if the user exists in the backend
          try {
            await axios.get(
              `${process.env.REACT_APP_BACKEND_API_URL}/api/auth/user/getUser`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            setUserLoggedIn(true);
          } catch (err) {
            // If there's an auth error (e.g., token expired despite refresh attempt)
            if (err.response && (err.response.status === 401 ||
              err.response.data?.error?.code === 'auth/id-token-expired')) {
              // Try one more time with a forced refresh
              try {
                // Clear auth state and attempt re-login
                await auth.signOut();
                setUserLoggedIn(false);
                // The user will need to login again
                console.error("Authentication session expired. Please log in again.");
              } catch (innerErr) {
                console.error("Error during auth cleanup:", innerErr);
                setUserLoggedIn(false);
              }
            } else {
              console.error("Error verifying user:", err);
              setUserLoggedIn(false);
            }
          }
        } catch (err) {
          console.error("Error getting ID token:", err);
          setUserLoggedIn(false);
        } finally {
          setIsLoading(false);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <LoadingIndicator showLogo={true} className="h-screen w-screen" />;
  }

  return (
    <div className="App min-h-screen" data-theme={`${theme}`}>
      <BrowserRouter future={routerFutureConfig}>
        <ToastContainer
          position="bottom-right"
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme={theme}
        />

        <div className={`pages`}>
          {/* Public routes that don't require authentication */}
          <Routes>
            {/* Public Link in Bio route */}
            <Route path="link/:customUrl" element={
              <Suspense fallback={<PageLoader />}>
                <LinkInBioPublic />
              </Suspense>
            } />

            {/* If logged in, show the workspace routes */}
            <Route path="/*" element={
              userLoggedIn ? (
                <WorkspaceProvider>
                  <WorkspaceRoutes />
                </WorkspaceProvider>
              ) : (
                <Suspense fallback={<PageLoader />}>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    {/* If not logged in, redirect everything else to /login */}
                    <Route path="*" element={<Navigate to="/login" />} />
                  </Routes>
                </Suspense>
              )
            } />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
